import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import Peer from "simple-peer";
import Icon from './Icon';
import { Howl } from "howler";
import RoomJoinSound from "./sounds/notif.wav";
import ChatSound from "./sounds/bubble.wav";

const Video = (props) => {
  const ref = useRef();
  const screenBeingShared = document.querySelector('.fs')?.id;

  useEffect(() => {
    props.peer.on("stream", stream => {
      ref.current.srcObject = stream;
    })
  }, []);
  if (screenBeingShared) {
    return (
      <div className={"user-video-container" + (screenBeingShared !== props.id ? " hidden" : "")} id={props.id}>
        <video className="user-video" playsInline autoPlay ref={ref} controls/>
        <p className="display-name" spellCheck="false"></p>
      </div>
    );
  } else {
    return (
      <div className="user-video-container" id={props.id}>
        <video className="user-video" playsInline autoPlay ref={ref} controls/>
        <p className="display-name" spellCheck="false"></p>
      </div>
    );
  }
}

const Room = (props) => {
  const [peers, setPeers] = useState([]);
  const [myPeerId, setMyPeerId] = useState("");
  const [myOldName, setMyOldName] = useState("");
  const [myCurrentName, setMyCurrentName] = useState("");
  const socketRef = useRef();
  const userVideo = useRef();
  const userStream = useRef();
  const userScreenStream = useRef();
  const peersRef = useRef([]);
  const roomID = props.match.params.roomID;
  const roomName = window.location.pathname.replace('/room', '');
  const [manyUsers, setManyUsers] = useState(false);  // this is purely used for styling purposes (responsive videos)

  const [mutedMyself, setMutedMyself] = useState(false);
  const [disabledMyVideo, setDisabledMyVideo] = useState(false);
  const [screenShared, setScreenShared] = useState(false);
  const sharingScreen = useRef(false);
  const [someoneSharing, setSomeoneSharing] = useState(false);
  const [someoneSharingFS, setSomeoneSharingFS] = useState(true);
  const [copyURLNotice, setCopyURLNotice] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const msgSenderTrackerRef = useRef([]);
  const [chatHidden, setChatHidden] = useState(true);

  useEffect(() => {
    socketRef.current = io.connect("/");
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
      userVideo.current.srcObject = stream;   // allow us to see our own video
      userStream.current = stream;
      socketRef.current.emit("join room", roomID);    // alert room that you have joined

      socketRef.current.on("all users", users => {    // once we join, make Peer object for everyone else already in room
        const peers = [];
        const cachedName = window.localStorage.getItem("displayName");
        let myName;

        if (props.location.state) {   // if you made the room, you already have a name
          myName = props.location.state.detail;
        } else {
          myName = cachedName ? cachedName : `Guest ${users.length}`;
        }

        setMyPeerId(socketRef.current.id);
        setMyCurrentName(myName);
        localStorage.setItem('displayName', myName);

        // resizes videos dynamically based on # users
        if (users.length > 1) {
          setManyUsers(true);
        }

        users.forEach(userID => {
          const peer = createPeer(userID, socketRef.current.id, stream); // sending our id and stream to the user

          peersRef.current.push({     // push user's info to our peer ref array
            peerID: userID,
            peer,
          });

          peers.push({
            peerID: userID,
            peer,
          });

          peer.write(JSON.stringify({ type: "username", content: { id: socketRef.current.id, oldName: '', displayName: myName }}));
        })

        setPeers(peers);
      });

      socketRef.current.on("user joined", payload => {    // handles user joining your room
        playSound(RoomJoinSound);

        const peer = addPeer(payload.signal, payload.callerID, stream);     // get that user's info

        // resizes videos dynamically based on # users
        if (document.querySelectorAll(".user-video-container").length > 1) {
          setManyUsers(true);
        }

        peersRef.current.push({     // add user to your peer ref array
          peerID: payload.callerID,
          peer,
        })

        const peerObj = {
          peerID: payload.callerID,
          peer
        }

        setPeers(users => [...users, peerObj]);

        const myName = document.querySelector('.display-name.me')?.innerText;
        peer.write(JSON.stringify({ type: "username", content: { id: socketRef.current.id, oldName: '', displayName: myName }}));
      });

      socketRef.current.on("receiving returned signal", payload => {
          const item = peersRef.current.find(p => p.peerID === payload.id);
          item.peer.signal(payload.signal);
      });

      socketRef.current.on("user left", id => {
        const peerObj = peersRef.current.find(p => p.peerID === id);
        if (peerObj) peerObj.peer.destroy();

        // resizes videos dynmically based on # users
        if (document.querySelectorAll(".user-video-container").length < 4) {
          setManyUsers(false);
        }

        peersRef.current = peersRef.current.filter(p => p.peerID !== id);
        setPeers(peers => peers.filter(p => p.peerID !== id));
      });

      socketRef.current.on("room full", () => {
        alert("room is full");
        props.history.push('/');
        window.location.reload();
      });
    })
  }, []);

  function saveOldName() {
    setMyOldName(myCurrentName);
    document.getElementById("edit-name").addEventListener('keypress', handleKeypress)
  }

  function confirmName() {
    const newName = document.getElementById("edit-name");
    newName.removeEventListener('keypress', handleKeypress);

    if (!newName.innerText.trim()) {
      newName.innerText = myCurrentName;
      return;
    }

    setMyCurrentName(newName.innerText);
    localStorage.setItem('displayName', newName.innerText);

    peersRef.current.forEach(peer => {
      peer.peer.write(JSON.stringify({ type: "username", content: { id: socketRef.current.id, oldName: myOldName, displayName: newName.innerText }}));
    });
  }

  function handleKeypress(e) {
    if (e.key === "Enter") {
      e.target.blur();
    }

    if (e.target.innerText.length > 15)  {
      e.preventDefault();
    }
  }

  // Creates Peer from the perspective of person joining
  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      objectMode: true,
      stream,
    });

    peer.on("signal", signal => {
      socketRef.current.emit("sending signal", { userToSignal, callerID, signal })
    });

    peer.on("data", handleReceivingData);

    return peer;
  }

  // Creates Peer from the perspective of person seeing new person joining room
  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      objectMode: true,   // parses data channel objects as strings
      stream,
    });

    if (sharingScreen.current) {   // user joined room where someone is currently sharing screen
      peer.replaceTrack(userStream.current.getTracks()[1], userScreenStream.current, peer.streams[0]);
      peer.write(JSON.stringify({ type: "start screen share", content: { id: socketRef.current.id}}));
    }

    // We receive signal from user that joined our room, and we send our own to server
    peer.on("signal", signal => {
      socketRef.current.emit("returning signal", { signal, callerID })
    });

    peer.on("data", handleReceivingData);
    peer.signal(incomingSignal);

    return peer;
  }

  function handleReceivingData(data) {
    const parsedData = JSON.parse(data);

    if (parsedData.type === "msg") {
      playSound(ChatSound);

      const alert = document.getElementById("chat-alert");
      const chatHidden = document.querySelector(".chat.hidden");
      let hideName = false;

      if (chatHidden) alert.classList.remove("hidden");

      if (msgSenderTrackerRef.current.length) {
        hideName = msgSenderTrackerRef.current[msgSenderTrackerRef.current.length-1] === false ? true : false;
      }

      msgSenderTrackerRef.current.push(false);
      setMessages(messages => [...messages, { yours: false, sender: parsedData.sender, value: parsedData.content, hideName: hideName }]);
      scrollToBottomOfChat();
    }
    else if (parsedData.type === "username") {
      const tag = document.getElementById(parsedData.content.id).querySelector('.display-name');
      const previousTags = document.querySelectorAll('.message__sender.partner');

      tag.innerText = parsedData.content.displayName;
      previousTags.forEach(tag => {
        if (tag.innerText === parsedData.content.oldName) {
          tag.innerText = parsedData.content.displayName;
        }
      });
    }
    else if (parsedData.type === "start screen share") {
      setSomeoneSharing(true);
      const videos = document.querySelectorAll('.user-video-container');

      videos.forEach(video => {
        if (video.id === parsedData.content.id) {
          video.classList.add("fs");
          video.classList.add("sharer");
        } else {
          video.classList.add("hidden");
          video.classList.add("watcher");
        }
      });

    }
    else if (parsedData.type === "end screen share") {
      setSomeoneSharing(false);
      const videos = document.querySelectorAll('.user-video-container');

      videos.forEach(video => {
        if (video.id === parsedData.content.id) {
          video.classList.remove("fs");
          video.classList.remove("sharer");
        } else {
          video.classList.remove("hidden");
          video.classList.remove("watcher");
        }
      });
    }
  }

  function muteMyself() {
    setMutedMyself(muted => !muted);
    userStream.current.getAudioTracks()[0].enabled = !userStream.current.getAudioTracks()[0].enabled;
  }

  function disableVideo() {
    setDisabledMyVideo(disabled => !disabled);
    userStream.current.getVideoTracks()[0].enabled = !userStream.current.getVideoTracks()[0].enabled;
  }

  function sendMessage(e) {
    e.preventDefault();

    if (message) {
      const highlightedText = highlightLinksInText(message);
      peersRef.current.forEach(peer => {
        peer.peer.write(JSON.stringify({ type: "msg", sender: myCurrentName, content: highlightedText }));
      })

      let hideName = false;

      if (msgSenderTrackerRef.current.length) {
        hideName = msgSenderTrackerRef.current[msgSenderTrackerRef.current.length-1] === true ? true : false;
      }

      msgSenderTrackerRef.current.push(true);
      setMessages(messages => [...messages, { yours: true, sender: myCurrentName, value: highlightedText, hideName: hideName }]);
      setMessage("");
      scrollToBottomOfChat()
    } else {
      setMessage("");
    }
  }

  function highlightLinksInText(text) {
    // If a user sends a chat message which contains links, we hyperlink them and highlight them
    const urlRegex = /(http:\/\/|https:\/\/|ftp:\/\/|ftps:\/\/)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,10}(\/\S*)?/ig
    const matches = text.match(urlRegex);
    let textWithHighlightedLinks = text;

    // The text contains links => we hyperlink them
    if (matches) {
      matches.forEach(match => {
        if (match.includes("http") || match.includes("https")) {
          textWithHighlightedLinks = textWithHighlightedLinks.replace(match, `<a href="${match}" target="_blank">${match}</a>`);
        } else {
          textWithHighlightedLinks = textWithHighlightedLinks.replace(match, `<a href="http://${match}" target="_blank">${match}</a>`);
        }
      })
    }

    return textWithHighlightedLinks;
  }


  function scrollToBottomOfChat() {
    setTimeout(() => {
      const el = document.getElementById("messages");
      el.scrollTop = el.scrollHeight;
    }, 0)
  }

  function playSound(src) {
    const sound = new Howl({ src: src });
    sound.play();
  }

  function renderMessage(message, index) {
    if (message.yours) {
      return (
        <div key={index} className={"message" + (message.hideName ? " chained" : "") + (!index ? " first"  : "")}>
          <div className={"message__sender me" + (message.hideName ? " hidden" : "")}>{myCurrentName}</div>
          <div className="bubble bubble--me" dangerouslySetInnerHTML={{ __html: `${message.value}` }} />
        </div>
      )
    }

    return (
      <div key={index} className={"message" + (message.hideName ? " chained" : "") + (!index ? " first"  : "")}>
        <div className={"message__sender partner" + (message.hideName ? " hidden" : "")}>{message.sender}</div>
        <div className="bubble bubble--partner" dangerouslySetInnerHTML={{ __html: `${message.value}` }} />
      </div>
    )
  }

  function toggleChat() {
    setChatHidden(hidden => !hidden);

    const alert = document.getElementById("chat-alert");
    alert.classList.add("hidden");
  }

  function shareScreen() {
    if (!screenShared) {
      navigator.mediaDevices.getDisplayMedia({ cursor: true }).then(stream => {
        const screenTrack = stream.getTracks()[0];
        userScreenStream.current = screenTrack;
        sharingScreen.current = true;
        setScreenShared(true);

        peersRef.current.forEach(peer => {
          peer.peer.replaceTrack(userStream.current.getTracks()[1], screenTrack, peer.peer.streams[0]);
          peer.peer.write(JSON.stringify({ type: "start screen share", content: { id: socketRef.current.id}}));
        })

        screenTrack.onended = function() {
          sharingScreen.current = false;
          setScreenShared(false);

          peersRef.current.forEach(peer => {
            peer.peer.replaceTrack(screenTrack, userStream.current.getTracks()[1], peer.peer.streams[0]);
            peer.peer.write(JSON.stringify({ type: "end screen share", content: { id: socketRef.current.id}}));
          })
        }
      });
    }
  }

  function copyRoomURL() {
    const textarea = document.createElement("textarea");
    const url = window.location.href;
    textarea.value = url;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    textarea.remove();

    if (!copyURLNotice) {
      setCopyURLNotice(true);
      const noticeEl = document.getElementById("copyurl-notice");
      noticeEl.classList.remove("hidden");

      setTimeout(() => {
        noticeEl.classList.add("hidden");
        setCopyURLNotice(false);
      }, 3000)
    }
  }

  function toggleScreenShareFS() {
    const videos = document.querySelectorAll('.user-video-container');

    videos.forEach(video => {
      if (video.classList.contains("sharer")) {
        if (video.classList.contains("fs")) {
          video.classList.remove("fs")
          setSomeoneSharingFS(false);
        } else {
          video.classList.add("fs");
          setSomeoneSharingFS(true);
        }
      } else {
        video.classList.contains("hidden") ? video.classList.remove("hidden") : video.classList.add("hidden");
      }
    });
  }

  return (
    <div className="content">
      <div className="header">
        <div className="header__info">
          <h1 className="website-title"><Link to="/">Cortado</Link></h1>
          <button className="room-name-btn" id="copyurl" onClick={copyRoomURL}>{roomName}</button>
          <div className="notice notice--url hidden" id="copyurl-notice">Copied Room URL</div>
        </div>
        <div className="controls">
          <button className={ mutedMyself ? "btn btn--primary mute active" : "btn btn--primary mute" } onClick={muteMyself}>
            { mutedMyself ? <Icon title="muted" /> : <Icon title="unmuted" /> }
          </button>
          <button className={ disabledMyVideo ? "btn btn--primary video active" : "btn btn--primary video" } onClick={disableVideo}>
            { disabledMyVideo ? <Icon title="video-off" /> : <Icon title="video-on" /> }
          </button>
          { someoneSharing ?
            <button className="btn btn--primary fullscreen-toggle" onClick={toggleScreenShareFS}>
              { someoneSharingFS ? <Icon title="disableFullScreen" /> : <Icon title="enableFullScreen" />}
            </button>
          :
            <button className={ screenShared ? "btn btn--primary screen active" : "btn btn--primary screen" } onClick={shareScreen}>
              {screenShared ?  <Icon title="screenActive" /> : <Icon title="screenInactive" /> }
            </button>
          }
          <button className={ chatHidden ? "btn btn--primary chatting" : "btn btn--primary chatting active" } onClick={toggleChat}>
            { chatHidden ? <Icon title="chat" /> : <Icon title="no-chat" />}
            <div className="chat-alert hidden" id="chat-alert"></div>
          </button>
        </div>
      </div>
      <div className="media">
        <div className={"user-videos " + (!chatHidden ? "chat-open " : "") + (manyUsers ? "many-users" : "")}>
          <div className="user-video-container me" id={myPeerId}>
            <video className="user-video" muted ref={userVideo} autoPlay playsInline />
            <p className="display-name me" contentEditable="true" id="edit-name" onClick={saveOldName} onBlur={confirmName} suppressContentEditableWarning="true" spellCheck="false">{myCurrentName}</p>
          </div>
          {peers.map((peer) => {
            return (
              <Video key={peer.peerID} peer={peer.peer} id={peer.peerID} />
            );
          })}
        </div>
        <div className={chatHidden ? "chat hidden" : "chat"}>
          <div id="messages" className="messages">
            {messages.map(renderMessage)}
          </div>
          <form className="text" onSubmit={sendMessage}>
            <div className="chat-actions">
              <input id="text-input" type="text" className="text-field" value={message} onChange={e => setMessage(e.target.value)} placeholder="Say something..." />
            </div>
            <input type="submit" value="Send" className="btn btn--secondary" title="Send message to partner"/>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Room;
