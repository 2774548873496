import React, { useState } from "react";
var generate = require('project-name-generator');

function CreateRoom(props) {
  const [roomName, setRoomName] = useState('');
  const [displayName, setDisplayName] = useState(window.localStorage.getItem("displayName"));

  function createRoom(e) {
    e.preventDefault();
    let parsedRoomName = roomName.replace(/[~`!@#$%^&*()?><"':;{}|\\/.]/g, '').replace(/\s+/g, '-').toLowerCase();

    if (!parsedRoomName) parsedRoomName = generate({ words: 2 }).dashed;  // failsafe

    props.history.push({
      pathname: `/room/${parsedRoomName}`,
      state: { detail: displayName ? displayName : 'admin' }
    })
    setRoomName('');
  }

  return (
    <div className="welcome">
      <h1 className="website-title">Cortado <span role="img" aria-labelledby="img">☕</span></h1>
      <form onSubmit={createRoom} className="room-info">
        <h4 className="title">Room Name</h4>
        <input type="text" className="text-field" value={roomName} onChange={e => setRoomName(e.target.value)} />
        <h4 className="title">Your display name</h4>
        <input type="text" className="text-field" value={displayName} onChange={e => setDisplayName(e.target.value)} />
        <input type="submit" className="btn btn--secondary" value="Create"/>
      </form>
      <p className="name-notice">If left blank, random names are generated</p>
    </div>
  );
}

export default CreateRoom;
